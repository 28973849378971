import moment from 'moment';

import LineChart from './components/Chart/LineChart/LineChart';
import Piechart from './components/Chart/PieChart/PieChart';
import StackedBarChart from './components/Chart/StackedBarChart/StackedBarChart';
import {
  BAR_CHART,
  DATE_CONVERT_UTC_FORMAT_API,
  DATE_FORMAT,
  DATE_FORMAT_API,
  LINE_CHART,
  PIE_CHART,
} from './constants/Constants';

/**
 * Format date to be displayed in the UI
 * @param {string | Date} date - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const formattedDate = (date, defaultFormat = DATE_FORMAT) => {
  if (!date) return null;

  return moment.utc(date).local().format(defaultFormat); // Convert UTC to local and format it
};

/**
 * Format date to be sent to API
 * @param {string | Date} receivedDate - date in string or Date format
 * @returns {string} - formated date in YYYY-MM-DD
 */
export const filterDateFormatted = (receivedDate) => {
  const date = new Date(receivedDate);
  const newDate = moment(date).utc().format(DATE_FORMAT_API);

  return newDate;
};

export const datePickerFormat = (receivedDate) =>
  moment.utc(receivedDate).local().toDate();

/**
 * Format date to be sent to API
 * @param {string | Date} date - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT_API] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const formatDateAPI = (
  date,
  defaultFormat = DATE_FORMAT_API,
  defaultUTCFormat = DATE_CONVERT_UTC_FORMAT_API
) => {
  if (!date) return null;

  return moment(date, defaultUTCFormat).utc().format(defaultFormat);
};

export const debounce = (func, wait) => {
  let timeout;

  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export function getUniqueFrequencies(frequencyList) {
  const uniqueFrequencies = {};

  frequencyList.forEach((freq) => {
    uniqueFrequencies[freq.code] = freq;
  });

  return Object.values(uniqueFrequencies);
}

export const clearTextfields = (setFun, name) => setFun(name, '');

export const displayChart = (item) => {
  const data = item.widget_data[item?.type];

  switch (item?.type) {
    case PIE_CHART:
      return <Piechart data={data?.data} isRadius={item?.is_radius} />;
    case BAR_CHART:
      return (
        <StackedBarChart
          colors={data?.colors}
          data={data?.data}
          xLabels={data?.labels}
        />
      );
    case LINE_CHART:
      return <LineChart data={data?.data} labels={data?.labels} />;
    // case GAUGE_CHART:
    //   break;
    // case SPARKLINE_CHART:
    //   break;
    // case SCATTER_CHART:
    //   break;
    default:
  }
};

export const isCreatedWithinLastFiveMinutes = (createdAt) => {
  if (!createdAt) return false;
  const nowUTC = moment.utc();
  const createdAtDate = moment.utc(createdAt);

  const diffInMinutes = nowUTC.diff(createdAtDate, 'minutes');

  return diffInMinutes <= 5;
};

export const findLabelByValue = (list, value) => {
  const item = list.find((option) => option.value === value);

  return item ? { label: item.label, value: item.value } : null;
};
