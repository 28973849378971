import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  default as ReceiptLongOutlined,
  default as ReceiptLongOutlinedIcon,
} from '@mui/icons-material/ReceiptLongOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';

// Internal
import { CUSTOMER, GLOBAL_ADMIN, OFFICE_STAFF, SUPER_ADMIN } from './Roles';
import { ROUTENAME } from './RoutesConstants';

export const permissionRoute = {
  global_admin: {
    permission: [
      'dashboard',
      'fire_companies',
      'company_list',
      'super_admins',
      'users',
      'global_admins',
      'templates',
      'email_template',
      'report',
      'quote',
      'invoice',
      'purchase_orders',
      'forms',
      'routine_services',
      'assets',
      'invoices',
      'control_panel',
    ],
    routes: [
      '/dashboard',
      '/fire-company/companies',
      '/fire-company/super-admins',
      '/users/global-admins',
      '/templates/email-template',
      '/templates/reports',
      '/templates/quotes',
      '/templates/invoice',
      '/templates/purchase-orders',
      '/forms/routine-services',
      '/forms/assets',
      '/invoices',
      '/control-panel',
    ],
  },
  super_admin: {
    permission: [
      'dashboard',
      'customers',
      'name',
      'contacts',
      'properties',
      'scheduling',
      'routine_services',
      'service_requests',
      'schedule_board',
      'work_orders',
      'users',
      'super_admin',
      'office_staffs',
      'staff_management',
      'technicians',
      'quotes',
      'defects_quotes',
      'quotes_services',
      'warehouse',
      'settings',
      'suppliers',
      'product_catalogue',
      'products',
      'assets',
      'invoices',
      'control_panel',
      'reports',
    ],
    routes: [
      '/dashboard',
      '/customers/customer',
      '/customers/contacts',
      '/customers/properties',
      '/scheduling/routine-services',
      '/scheduling/service-requests',
      '/scheduling/schedule-board',
      '/scheduling/work-orders',
      '/users/super-admin',
      '/users/office-staff',
      '/users/staff-management',
      '/users/technician',
      '/quotes/defects-quotes',
      '/quotes/quotes-services',
      '/warehouse/products',
      '/warehouse/assets',
      '/warehouse/invoices',
      '/settings/suppliers',
      '/settings/product-catalogue',
      '/control-panel',
      '/reports',
    ],
  },
  customer: {
    permission: [
      'dashboard',
      'properties',
      'assets',
      'quotes',
      'invoices',
      'defects',
      'reports',
      'make_a_request',
      'routine_activities',
      'reactive_task',
      'documents',
    ],
    routes: [
      '/dashboard/properties',
      '/dashboard/quotes',
      '/dashboard/invoices',
      '/dashboard/defects',
      '/reports',
      '/make-request',
      '/assets',
      '/routine-activities',
      '/reactive-task',
      '/documents',
    ],
  },
};

export const SIDEBAR_MENU = {
  [GLOBAL_ADMIN]: [
    {
      id: 'dashboard',
      label: 'Dashboard',
      path: ROUTENAME.DASHBOARD,
      icon: <DashboardOutlinedIcon sx={{ color: 'black' }} />,
    },
    {
      id: 'fire_companies',
      label: 'Fire Companies',
      path: ROUTENAME.FIRE_COMPANY,
      subMenu: [
        {
          id: 'company_list',
          label: 'Company List',
          icon: <ListAltOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.COMPANIES,
        },
        {
          id: 'super_admins',
          label: 'Super Admins',
          icon: <ManageAccountsOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SUPER_ADMINS,
        },
      ],
    },
    {
      id: 'users',
      label: 'Users',
      path: ROUTENAME.USERS,
      subMenu: [
        {
          id: 'global_admins',
          label: 'Global Admins',
          icon: <PeopleAltOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.GLOBAL_ADMIN,
        },
      ],
    },
    {
      id: 'templates',
      label: 'Templates',
      path: ROUTENAME.TEMPLATES,
      subMenu: [
        {
          id: 'email_template',
          label: 'Email',
          icon: <EmailOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.EMAIL_TEMPLATE,
        },
        {
          id: 'report',
          label: 'Report',
          icon: <ReportGmailerrorredOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.REPORTS,
        },
        {
          id: 'quote',
          label: 'Quote',
          icon: <ReceiptLongOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.QUOTES,
        },
        {
          id: 'invoice',
          label: 'Invoice',
          icon: <ReceiptOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.INVOICE,
        },
        {
          id: 'purchase_orders',
          label: 'Purchase Order',
          icon: <InventoryOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PURCHASE_ORDERS,
        },
      ],
    },

    {
      id: 'forms',
      label: 'Forms',
      path: ROUTENAME.FORMS,
      subMenu: [
        {
          id: 'routine_services',
          label: 'Routine Services',
          icon: <HistoryToggleOffOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.ROUTINE_SERVICES,
        },
        {
          id: 'assets',
          label: 'Assets',
          icon: <CategoryOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.ASSETS,
        },
      ],
    },
    {
      id: 'invoices',
      label: 'Invoices',
      icon: <ReceiptOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.INVOICES,
    },
    {
      id: 'control_panel',
      label: 'Control Panel',
      icon: <SettingsOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.CONTROL_PANEL,
    },
  ],
  [SUPER_ADMIN]: [
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: <DashboardOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.DASHBOARD,
    },
    {
      id: 'customers',
      label: 'Customers',
      path: ROUTENAME.CUSTOMERS,
      subMenu: [
        {
          id: 'name',
          label: 'Name',
          icon: <PersonOutlineOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.CUSTOMER,
        },
        {
          id: 'contacts',
          label: 'Contacts',
          icon: <ContactPageOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.CONTACTS,
        },
        {
          id: 'properties',
          label: 'Properties',
          icon: <MapsHomeWorkOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PROPERTIES,
        },
      ],
    },
    {
      id: 'scheduling',
      label: 'Scheduling',
      path: ROUTENAME.SCHEDULING,
      subMenu: [
        {
          id: 'routine_services',
          label: 'Routine Services',
          icon: <HistoryToggleOffOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.ROUTINE_SERVICES,
        },
        {
          id: 'service_requests',
          label: 'Service Requests',
          icon: <InventoryOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SERVICE_REQUESTS,
        },
        {
          id: 'work_orders',
          label: 'Work Orders',
          icon: <BallotOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.WORK_ORDERS,
        },
        {
          id: 'schedule_board',
          label: 'Schedule Board',
          icon: <CalendarMonthOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SCHEDULE_BOARD,
        },
      ],
    },
    {
      id: 'users',
      label: 'Users',
      path: ROUTENAME.USERS,
      subMenu: [
        {
          id: 'super_admin',
          label: 'Super Admins',
          icon: <ManageAccountsOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SUPER_ADMIN,
        },
        {
          id: 'office_staffs',
          label: 'Office Staffs',
          icon: <AccountCircleOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.OFFICE_STAFF,
        },
        {
          id: 'staff_management',
          label: 'Managements',
          icon: <SwitchAccountOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.STAFF_MANAGEMENT,
        },
        {
          id: 'technicians',
          label: 'Technicians',
          icon: <EngineeringOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.TECHNICIAN,
        },
      ],
    },
    {
      id: 'quotes',
      label: 'Quotes',
      path: ROUTENAME.QUOTES,
      subMenu: [
        {
          id: 'defects_quotes',
          label: 'Defetcs Quotes',
          icon: <ReportProblemOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.DEFECTS_QUOTES,
        },
        {
          id: 'quotes_services',
          label: 'Quotes Services',
          icon: <ReceiptLongOutlined sx={{ color: 'black' }} />,
          path: ROUTENAME.QUOTES_SERVICES,
        },
      ],
    },
    {
      id: 'settings',
      label: 'Settings',
      path: ROUTENAME.SETTINGS,
      subMenu: [
        {
          id: 'suppliers',
          label: 'Suppliers',
          icon: <LocalShippingOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SUPPLIERS,
        },
        {
          id: 'product_catalogue',
          label: 'Product Catalogue',
          icon: <StorefrontIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PRODUCT_CATALOGUE,
        },
      ],
    },
    {
      id: 'warehouse',
      label: 'Warehouse',
      path: ROUTENAME.WAREHOUSE,
      subMenu: [
        {
          id: 'products',
          label: 'Products',
          icon: <StorefrontOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PRODUCTS,
        },
        {
          id: 'assets',
          label: 'Assets',
          icon: <CategoryOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.ASSETS,
        },
        {
          id: 'invoices',
          label: 'Invoices',
          icon: <ReceiptOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.INVOICES,
        },
      ],
    },
    {
      id: 'control_panel',
      icon: <SettingsOutlinedIcon sx={{ color: 'black' }} />,
      label: 'Control Panel',
      path: ROUTENAME.CONTROL_PANEL,
    },
    {
      id: 'reports',
      icon: <SummarizeOutlinedIcon sx={{ color: 'black' }} />,
      label: 'Reports',
      path: ROUTENAME.REPORTS,
    },
  ],
  [CUSTOMER]: [
    {
      id: 'dashboard',
      label: 'Dashboard',
      path: ROUTENAME.DASHBOARD,
      subMenu: [
        {
          id: 'properties',
          label: 'Properties',
          icon: <MapsHomeWorkOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PROPERTIES,
          isAccessible: true,
        },
        {
          id: 'quotes',
          label: 'Quotes',
          icon: <ReceiptLongOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.QUOTES,
          isAccessible: true,
        },
        {
          id: 'invoices',
          label: 'Invoices',
          icon: <ReceiptOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.INVOICES,
        },
        {
          id: 'defects',
          label: 'Defects',
          icon: <ReportProblemOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.DEFECTS,
        },
      ],
    },
    {
      id: 'reports',
      label: 'Reports',
      icon: <SummarizeOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.REPORTS,
    },
    {
      id: 'make_a_request',
      label: 'Make a Request',
      icon: <RequestQuoteOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.MAKE_REQUEST,
    },
    {
      id: 'assets',
      label: 'Assets',
      icon: <CategoryOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.ASSETS,
    },
    {
      id: 'routine_activities',
      label: 'Routine Activities',
      icon: <HistoryToggleOffOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.ROUTINE_ACTIVITIES,
    },
    {
      id: 'reactive_task',
      label: 'Reactive Tasks',
      icon: <TaskOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.REACTIVE_TASK,
    },
    {
      id: 'documents',
      label: 'Documents',
      icon: <DocumentScannerOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.DOCUMENTS,
    },
  ],
  [OFFICE_STAFF]: [],
};
