import { Navigate, createBrowserRouter } from 'react-router-dom';

// Internal
import ComingSoon from './components/CommonComponents/ComingSoon';
import Companies from './components/Company/Companies';
import CustomerPropertyDashboard from './components/Dashboard/CustomerPropertyDashboard';
import Dashboard from './components/Dashboard/Dashboard';
import EmailTemplate from './components/EmailTemplate/EmailTemplate';
import InvoiceDashboard from './components/InvoiceDashboard/InvoiceDashboard';
import LandingPage from './components/LandingPage';
import NoFound from './components/NotFound/NotFound';
import NotificationTemplate from './components/NotificationTemplate/NotificationTemplate';
import ProductCatalogue from './components/ProductCatalogue/ProductCatalogue';
import Properties from './components/Properties/Properties';
import QuotesDashboard from './components/QuotesDashboard/QuotesDashboard';
import Layout from './components/Shared/Layout';
import SuperAdmins from './components/SuperAdmin/SuperAdmin';
import Suppliers from './components/Suppliers/Suppliers';
import Customer from './components/Users/Customer/Customer';
import Technician from './components/Users/Technician/Technician';
import WorkOrder from './components/WorkOrder/WorkOrder';
import { ROUTENAME } from './constants/RoutesConstants';
import AuthLayout from './hoc/AuthLayout';
import AuthRoute from './hoc/AuthRoute';
import UnAuthLayout from './hoc/UnAuthLayout';

export const routes = createBrowserRouter([
  // Public route for the landing page
  {
    path: ROUTENAME.DEFAULT_ROUTE, // e.g., '/'
    element: (
      <UnAuthLayout>
        <LandingPage />
      </UnAuthLayout>
    ),
  },
  // Protected routes under AuthLayout
  {
    path: ROUTENAME.DEFAULT_ROUTE,
    element: (
      <AuthLayout>
        <Layout />
      </AuthLayout>
    ),
    children: [
      {
        index: true,
        path: ROUTENAME.DASHBOARD,
        element: (
          <AuthRoute>
            {/* Global Admin & Super Admin */}
            <Dashboard />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.SCHEDULING,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.ROUTINE_SERVICES} />,
          },
          {
            path: ROUTENAME.ROUTINE_SERVICES,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SERVICE_REQUESTS,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SCHEDULE_BOARD,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.WORK_ORDERS,
            element: (
              <AuthRoute>
                <WorkOrder />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.CUSTOMERS,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.CUSTOMER} />,
          },
          {
            path: ROUTENAME.CUSTOMER,
            element: (
              <AuthRoute>
                <Customer />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.CONTACTS,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PROPERTIES,
            element: (
              <AuthRoute>
                <Properties />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.USERS,
        children: [
          {
            path: ROUTENAME.SUPER_ADMIN,
            element: (
              <AuthRoute>
                <SuperAdmins />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.OFFICE_STAFF,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.STAFF_MANAGEMENT,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.TECHNICIAN,
            element: (
              <AuthRoute>
                <Technician />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.QUOTES,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.DEFECTS_QUOTES} />,
          },
          // Super Admin
          {
            path: ROUTENAME.DEFECTS_QUOTES,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.QUOTES_SERVICES,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.WAREHOUSE,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.PRODUCTS} />,
          },
          {
            path: ROUTENAME.PRODUCTS,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.ASSETS,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.INVOICES,
            element: (
              <AuthRoute>
                {/* Super Admin */}
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.USERS,
        children: [
          {
            path: ROUTENAME.GLOBAL_ADMIN,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.CONTROL_PANEL,
        element: (
          <AuthRoute>
            {/* Global Admin & Super Admin */}
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.REPORTS,
        element: (
          <AuthRoute>
            {/* Super Admin */}
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.FIRE_COMPANY,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.COMPANIES} />,
          },
          {
            path: ROUTENAME.COMPANIES,
            element: (
              <AuthRoute>
                <Companies />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SUPER_ADMINS,
            element: (
              <AuthRoute>
                <SuperAdmins />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.NOTIFICATION_TEMPLATE,
        element: (
          <AuthRoute>
            <NotificationTemplate />
          </AuthRoute>
        ),
      },

      {
        path: ROUTENAME.FORMS,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.ROUTINE_SERVICES} />,
          },
          {
            path: ROUTENAME.ROUTINE_SERVICES,
            element: (
              <AuthRoute>
                {/* Add dependencies in package.json for survey-core */}
                {/* <Forms />  */}
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.ASSETS,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.TEMPLATES,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.EMAIL_TEMPLATE} />,
          },
          {
            path: ROUTENAME.EMAIL_TEMPLATE,
            element: (
              <AuthRoute>
                <EmailTemplate />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.REPORTS,
            element: (
              <AuthRoute>
                {/* Global Admin */}
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.QUOTES,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.INVOICE,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PURCHASE_ORDERS,
            element: (
              <AuthRoute>
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.INVOICES,
        element: (
          <AuthRoute>
            {/* Global Admin */}
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.MAKE_REQUEST,
        element: (
          <AuthRoute>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.ASSETS,
        element: (
          <AuthRoute>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.ROUTINE_ACTIVITIES,
        element: (
          <AuthRoute>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.REACTIVE_TASK,
        element: (
          <AuthRoute>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.DOCUMENTS,
        element: (
          <AuthRoute>
            <ComingSoon />
          </AuthRoute>
        ),
      },

      {
        path: ROUTENAME.DASHBOARD,
        children: [
          {
            path: ROUTENAME.PROPERTIES,
            element: (
              <AuthRoute>
                <CustomerPropertyDashboard />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.QUOTES,
            element: (
              <AuthRoute>
                {/* Customer Quotes Dashboard */}
                <QuotesDashboard />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.INVOICES,
            element: (
              <AuthRoute>
                {/* Customer */}
                <InvoiceDashboard />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.DEFECTS,
            element: (
              <AuthRoute>
                {/* Customer */}
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.SETTINGS,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.SUPPLIERS} />,
          },
          {
            path: ROUTENAME.SUPPLIERS,
            element: (
              <AuthRoute>
                {/* Supplier */}
                <Suppliers />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PRODUCT_CATALOGUE,
            element: (
              <AuthRoute>
                {/* Product Catalogue */}
                <ProductCatalogue />
              </AuthRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NoFound />,
  },
]);
