import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqueId } from 'lodash';

import {
  DIVIDER_COLOR,
  SECONDARY,
  STATUS_COLORS,
} from '../../constants/Colors';
import {
  DASHBOARD,
  MONTHS_DROPDOWN,
  SIX_MONTHS,
  TWELEVE_MONTHS,
} from '../../constants/Constants';
import CustomLineChart from '../Chart/LineChart/CustomLineChart';
import StackedBarChart from '../Chart/StackedBarChart/StackedBarChart';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import DashboardCard from '../CommonComponents/DashboardCard';
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import MainWrapper from '../CommonComponents/MainWrapper';
import ChartCard from '../Dashboard/ChartCard';
import QuotesList from './QuotesList';

const defaultRangeData = {
  quoteStatusRange: MONTHS_DROPDOWN[0],
  quoteAmountRange: MONTHS_DROPDOWN[0],
};

//default filters
const defaultFilters = {
  dateRange: {
    fromData: null,
    toDate: null,
  },
  quoteType: null,
  properties: null,
};

const FiltersContainer = styled(Box)({
  borderRadius: '4px',
  backgroundColor: 'white',
  padding: '16px',
  display: 'flex',
  columnGap: '16px',
  rowGap: '16px',
  flexWrap: 'wrap',
});

const QuotesDashboard = () => {
  const { t } = useTranslation();

  const [ranges, setRanges] = useState(defaultRangeData);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [status, setStatus] = useState(DASHBOARD);

  const quoteCardData = [
    { title: t('common.status.pending'), value: 35 },
    { title: t('common.status.approved'), value: 53 },
    { title: t('common.status.inProgress'), value: 22 },
    { title: t('common.status.quotesRejected'), value: 12 },
    { title: t('common.status.overdue'), value: 26 },
    { title: t('attributes.customer.totalQuoteAmount'), value: '$3500' },
  ];

  const colors = [
    STATUS_COLORS.PENDING,
    STATUS_COLORS.APPROVED,
    STATUS_COLORS.IN_PROGRESS,
    STATUS_COLORS.REJECTED,
  ];

  //Static data for charts
  const sixMonthsData = {
    pending: [40, 100, 140, 200, 200, 250],
    approved: [20, 40, 50, 25, 60, 50],
    inProgress: [20, 40, 30, 25, 5, 15],
    rejected: [20, 23, 30, 40, 40, 30],
    quotesAmount: [2, 5.5, 2, 8.5, 1.5, 5],
    xAxisLabels: SIX_MONTHS,
  };
  const twelveMonthsData = {
    pending: [40, 100, 140, 200, 200, 250, 260, 300, 320, 400, 350, 280],
    approved: [20, 40, 50, 25, 60, 50, 55, 70, 80, 90, 60, 45],
    inProgress: [20, 40, 30, 25, 5, 15, 10, 35, 40, 20, 15, 25],
    rejected: [20, 23, 30, 40, 40, 30, 35, 45, 55, 60, 50, 40],
    quotesAmount: [2, 5.5, 2, 8.5, 1.5, 5, 7, 6.5, 8, 4, 5, 9],
    xAxisLabels: TWELEVE_MONTHS,
  };

  //Quote status data
  const currentQuoteStatusData =
    ranges.quoteStatusRange?.value === 6 ? sixMonthsData : twelveMonthsData;
  const quotesStatusXAxisLabels = currentQuoteStatusData.xAxisLabels;
  const quotesStatusData = [
    {
      data: currentQuoteStatusData.pending,
      label: t('common.status.pending'),
      id: 'pendingId',
      stack: 'total',
    },
    {
      data: currentQuoteStatusData.approved,
      label: t('common.status.approved'),
      id: 'approvedId',
      stack: 'total',
    },
    {
      data: currentQuoteStatusData.inProgress,
      label: t('common.status.inProgress'),
      id: 'inProgressId',
      stack: 'total',
    },
    {
      data: currentQuoteStatusData.rejected,
      label: t('common.status.rejected'),
      id: 'rejectedId',
      stack: 'total',
    },
  ];

  //Quote amount data
  const currentQuoteAmountData =
    ranges.quoteAmountRange?.value === 6 ? sixMonthsData : twelveMonthsData;
  const quotesAmountXAxisLabels = currentQuoteAmountData.xAxisLabels;
  const quotesAmountData = currentQuoteAmountData.quotesAmount;

  const handleResetFilterBtnClick = () => {
    setFilters(defaultFilters);
  };
  const handleRefreshDashboardBtnClick = () => {
    window.location.reload();
  };

  return (
    <Box
      sx={{
        bgcolor: status === DASHBOARD ? DIVIDER_COLOR : SECONDARY,
        height: '100%',
      }}
    >
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title={t('attributes.customer.quotes')}
          variant="body1"
          isStep={status !== DASHBOARD ? true : false}
          step={status}
          handleDefaultStep={() => setStatus(DASHBOARD)}
          btn={
            status === DASHBOARD
              ? [
                  <ResetFilterButton
                    onClick={handleResetFilterBtnClick}
                    disabled={
                      !(
                        filters.quoteType ||
                        filters.properties ||
                        filters.dateRange.fromData ||
                        filters.dateRange.toDate
                      )
                    }
                  />,
                  <FilterButton
                    onClick={() => setShowFilterOptions(!showFilterOptions)}
                    isActive={showFilterOptions}
                  />,
                  <RefreshDashboardButton
                    onClick={handleRefreshDashboardBtnClick}
                  />,
                ]
              : []
          }
        ></MainWrapper>
        {showFilterOptions && status === DASHBOARD && (
          <Box sx={{ paddingTop: '16px' }}>
            <FiltersContainer>
              <CustomDateRangePicker
                onOkClick={(val) => {
                  setFilters((prev) => ({
                    ...prev,
                    dateRange: { fromData: val[0], toDate: val[1] },
                  }));
                }}
                onClear={() => {
                  setFilters((prev) => ({
                    ...prev,
                    dateRange: { fromData: null, toDate: null },
                  }));
                }}
                fromDate={filters.dateRange?.fromData}
                toDate={filters.dateRange?.toDate}
              />
              <Autocomplete
                placeholder="Quote Type"
                options={[
                  { label: 'Quote 1', value: 'Quote 1' },
                  { label: 'Quote 2', value: 'Quote 2' },
                ]}
                value={filters?.quoteType}
                onChange={(e, newVal) =>
                  setFilters((prev) => ({ ...prev, quoteType: newVal }))
                }
                width="190px"
              />
              <Autocomplete
                placeholder="Propertiess"
                options={[
                  { label: 'Property 1', value: 'Property 1' },
                  { label: 'Property 2', value: 'Property 2' },
                ]}
                value={filters?.properties}
                onChange={(e, newVal) =>
                  setFilters((prev) => ({ ...prev, properties: newVal }))
                }
                width="190px"
              />
            </FiltersContainer>
          </Box>
        )}
      </Box>

      {status === DASHBOARD ? (
        <Box sx={{ bgcolor: DIVIDER_COLOR }}>
          <Box sx={{ width: '100%', padding: '16px 0 30px 0px' }}>
            <Grid container spacing={2}>
              {quoteCardData?.map((item) => (
                <Grid xs={12} sm={6} md={3} key={uniqueId('dashboardCard')}>
                  <DashboardCard
                    title={item.title}
                    value={item.value}
                    onClick={() => setStatus(item.title)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ display: 'flex', gap: '16px' }}>
            {/* Quote Status Chart */}
            <Box sx={{ flex: 1 }}>
              <ChartCard
                title={t('attributes.customer.quotesByStatus')}
                options={MONTHS_DROPDOWN}
                selectedType={ranges.quoteStatusRange}
                isEditableChart={false}
                setSelectedType={(id, val) =>
                  setRanges((prev) => ({
                    ...prev,
                    quoteStatusRange: val,
                  }))
                }
                height="257px"
                isSettingMode={true}
              >
                <StackedBarChart
                  colors={colors}
                  data={quotesStatusData}
                  xLabels={quotesStatusXAxisLabels}
                  legendPosition={{ vertical: 'top', horizontal: 'center' }}
                />
              </ChartCard>
            </Box>

            {/* Quote Amount Chart */}
            <Box sx={{ flex: 1 }}>
              <ChartCard
                title={t('attributes.customer.quoteAmount')}
                options={MONTHS_DROPDOWN}
                selectedType={ranges.quoteAmountRange}
                isEditableChart={false}
                setSelectedType={(id, val) =>
                  setRanges((prev) => ({
                    ...prev,
                    quoteAmountRange: val,
                  }))
                }
                height="257px"
                isSettingMode={true}
              >
                <CustomLineChart
                  xAxisLabels={quotesAmountXAxisLabels}
                  chartData={quotesAmountData}
                  prefix={'$'}
                  height={257}
                />
              </ChartCard>
            </Box>
          </Box>
        </Box>
      ) : (
        <QuotesList status={status} />
      )}
    </Box>
  );
};

export default QuotesDashboard;
